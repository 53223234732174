import {
  GET_USERS,
  GET_USER,
  GET_USERS_RAN_ONCE,
  SEARCH_USERS,
  LOADING_USERS,
  ERROR_USERS,
  UPDATE_USERS_PAGE,
  UPDATE_TOTAL_USERS_PAGE,
  UPDATE_USERS_SEARCH,
  UPDATE_USERS_ACTIVE_TAB,
} from "../Types/Types";

const initState = {
  loading: null,
  error: null,
  listOfUsers: null,
  listOfUsersRanOnce: false,
  singleUser: null,
  totalUsers: null,
  searchResults: null,
  searchPage: null,
  usersPage: {
    all: 0,
    0: 0,
    1: 0,
    2: 0,
    3: 0,
    deleted: 0,
  },
  totalUsersPage: {
    all: 0,
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    deleted: 0,
  },
  activeTab: "all",
  search: null,
  pagination: null,
  
};

const UserReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_USERS_RAN_ONCE:
      return {
        ...state,
        listOfUsersRanOnce: true,
      };
    case SEARCH_USERS:
      return {
        ...state,
        searchResults: action.searchResults,
        searchPage: action.searchPage,
      };
    case GET_USERS:
      return {
        ...state,
        listOfUsers: action.users,
        loading: false,
      };
    case GET_USER:
      return {
        ...state,
        singleUser: action.user,
        loading: false,
      };
    case LOADING_USERS:
      return {
        ...state,
        loading: true,
      };
    case ERROR_USERS:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case UPDATE_USERS_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload,
      };
    case UPDATE_USERS_PAGE:
      return {
        ...state,
        usersPage: {
          ...state.usersPage,
          [action.payload.level]: action.payload.value,
        }
      };
    case UPDATE_TOTAL_USERS_PAGE:
      return {
        ...state,
        totalUsersPage: {
          ...state.totalUsersPage,
          [action.payload.level]: action.payload.value,
        },
      };
    case UPDATE_USERS_SEARCH:
      return {
        ...state,
        search: action.payload,
      };
    default:
      return state;
  }
};

export default UserReducer;
