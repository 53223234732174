import axios from "axios";
import Api from "./api";
import {
  GET_USERS,
  GET_USER,
  GET_USERS_RAN_ONCE,
  LOADING_USERS,
  ERROR_USERS,
  UPDATE_USERS_PAGE,
  UPDATE_TOTAL_USERS_PAGE,
  UPDATE_USERS_SEARCH,
  UPDATE_USERS_ACTIVE_TAB,
} from "../Types/Types";

import { customToast } from "../../utils/customToast";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { Logout } from "./AuthActions";

export const getUsers = () => {
  return async (dispatch, getState) => {
    dispatch({ type: LOADING_USERS });
    const { usersPage, activeTab, search } = getState().users;

    console.log("usersPage", usersPage);

    console.log("search", search);
    console.log("activeTab", activeTab);

    if (!search) {
      dispatch(showLoading());
    }
    try {
      const currentPage = usersPage[activeTab];
      const query = {
        page: currentPage,
        level: activeTab,
        keyword: search,
      };
      const response = await Api.getUsers(query);
      // let stateListOfUsers;
      // if (getState().users.listOfUsers !== null) {
      //   stateListOfUsers = Array.from(
      //     new Set([
      //       ...getState().users.listOfUsers,
      //       ...response.data.users.results,
      //     ])
      //   );
      // } else {
      //   stateListOfUsers = Array.from(
      //     new Set([...response.data.users.results])
      //   );
      // }
      // console.log("users response", response);
      // console.log("list of users", stateListOfUsers);
      // dispatch({ type: GET_USERS, users: stateListOfUsers, page: response.data.users.length, totalUsers: response.data.total});
      dispatch({
        type: GET_USERS,
        users: response.data.rows,
        // page: response.data.page,
        // totalUsers: response.data.total,
        // pagination: response.data.users.pagination,
      });
      dispatch({
        type: UPDATE_TOTAL_USERS_PAGE,
        payload: {
          level: query.level,
          value: response.data.totalPages,
        },
      });
      dispatch(hideLoading());
      console.log("resultant state users", getState().users.listOfUsers);
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({ type: ERROR_USERS });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const searchUsers = (page, keyword) => {
  return async (dispatch, getState) => {
    dispatch({ type: LOADING_USERS });
    if (!keyword) {
      dispatch(showLoading());
    }
    try {
      const response = await Api.searchUsers(page, keyword);
      console.log(response);
      dispatch({
        type: GET_USERS,
        searchResults: response.data.data.users,
        searchPage: response.data.data.page,
      });
      dispatch(hideLoading());
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({ type: ERROR_USERS });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const getUser = (id, successCallback) => {
  return async (dispatch, getState) => {
    dispatch({ type: LOADING_USERS });
    dispatch(showLoading());
    try {
      const response = await Api.getUser(id);
      console.log(response);
      dispatch({ type: GET_USER, user: response.data });
      dispatch(hideLoading());
      if (response) {
        if (successCallback && typeof successCallback === "function") {
          successCallback(response);
        }
      }
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({ type: ERROR_USERS });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const creditUser = (id, state) => {
  return async (dispatch, getState) => {
    dispatch({ type: LOADING_USERS });
    dispatch(showLoading());
    try {
      const response = await Api.creditUser(state);
      console.log(response);
      customToast.success(`user was credited`);
      dispatch(getUser(id, state));
      dispatch(hideLoading());
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({ type: ERROR_USERS });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const debitUser = (id, state) => {
  return async (dispatch, getState) => {
    dispatch({ type: LOADING_USERS });
    dispatch(showLoading());
    try {
      const response = await Api.debitUser(state);
      console.log(response);
      customToast.success(`user was debited`);
      dispatch(getUser(id, state));
      dispatch(hideLoading());
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({ type: ERROR_USERS });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const suspendUser = (id) => {
  return async (dispatch, getState) => {
    dispatch({ type: LOADING_USERS });
    dispatch(showLoading());
    try {
      const response = await Api.suspendUser(id);
      console.log(response);
      customToast.success(`user was suspended`);
      dispatch(getUser(id));
      dispatch(hideLoading());
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({ type: ERROR_USERS });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const activateUser = (id) => {
  return async (dispatch, getState) => {
    dispatch({ type: LOADING_USERS });
    dispatch(showLoading());
    try {
      const response = await Api.activateUser(id);
      console.log(response);
      customToast.success(`user was reactivated`);
      dispatch(getUser(id));
      dispatch(hideLoading());
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({ type: ERROR_USERS });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const deleteUser = (id) => {
  return async (dispatch, getState) => {
    dispatch({ type: LOADING_USERS });
    dispatch(showLoading());
    try {
      const response = await Api.deleteUser(id);
      console.log(response);
      customToast.success(`user was deleted`);
      dispatch(getUser(id));
      dispatch(hideLoading());
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({ type: ERROR_USERS });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const restoreUser = (id) => {
  return async (dispatch, getState) => {
    dispatch({ type: LOADING_USERS });
    dispatch(showLoading());
    try {
      const response = await Api.restoreUser(id);
      console.log(response);
      customToast.success(`user was restored`);
      dispatch(getUsers(1, null));
      dispatch(hideLoading());
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({ type: ERROR_USERS });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const addBankAccountsAction = (userId, state, successCallback) => {
  return async (dispatch, getState) => {
    dispatch({ type: LOADING_USERS });
    dispatch(showLoading());
    try {
      const response = await Api.addBankAccounts(userId, state);
      console.log(response);
      customToast.success(`bank account approved`);
      dispatch(getUser(userId));
      dispatch(hideLoading());

      if (response) {
        if (successCallback && typeof successCallback === "function") {
          successCallback(response);
        }
      }
      dispatch(hideLoading());
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({ type: ERROR_USERS });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const deleteBankAccountAction = (userId, payload, successCallback) => {
  return async (dispatch, getState) => {
    dispatch({ type: LOADING_USERS });
    dispatch(showLoading());
    try {
      console.log("payload", payload);
      const response = await Api.deleteBankAccount(userId, payload);
      console.log(response);
      customToast.success(`bank account deleted`);
      dispatch(getUser(userId));
      dispatch(hideLoading());
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({ type: ERROR_USERS });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const debitPromoBalance = (userId, state, successCallback) => {
  return async (dispatch, getState) => {
    dispatch({ type: LOADING_USERS });
    dispatch(showLoading());
    try {
      const response = await Api.debitPromoBalance(userId, state);
      console.log(response);
      customToast.success(`promo balance debited`);
      dispatch(getUser(userId));
      dispatch(hideLoading());

      if (response) {
        if (successCallback && typeof successCallback === "function") {
          successCallback(response);
        }
      }
      dispatch(hideLoading());
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({ type: ERROR_USERS });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const creditPromoBalance = (userId, state, successCallback) => {
  return async (dispatch, getState) => {
    dispatch({ type: LOADING_USERS });
    dispatch(showLoading());
    try {
      const response = await Api.creditPromoBalance(userId, state);
      console.log(response);
      customToast.success(`promo balance credited`);
      dispatch(getUser(userId));
      dispatch(hideLoading());

      if (response) {
        if (successCallback && typeof successCallback === "function") {
          successCallback(response);
        }
      }
      dispatch(hideLoading());
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({ type: ERROR_USERS });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const resetReferralEarnings = (id) => {
  return async (dispatch, getState) => {
    dispatch({ type: LOADING_USERS });
    dispatch(showLoading());
    try {
      const response = await Api.resetReferralEarnings(id);
      console.log(response);
      customToast.success(`user referrals reset to 0`);
      dispatch(getUser(id));
      dispatch(hideLoading());
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({ type: ERROR_USERS });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const updateUserControls = (userId, state, successCallback) => {
  return async (dispatch, getState) => {
    dispatch({ type: LOADING_USERS });
    dispatch(showLoading());
    try {
      console.log("state", state);
      const response = await Api.updateUserControls(userId, state);
      console.log(response);
      customToast.success(`user controls updated`);
      dispatch(getUser(userId));
      dispatch(hideLoading());

      if (response) {
        if (successCallback && typeof successCallback === "function") {
          successCallback(response);
        }
      }
      dispatch(hideLoading());
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({ type: ERROR_USERS });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const updateUsersActiveTab = (payload) => {
  return async (dispatch, getState) => {
    dispatch({ type: UPDATE_USERS_ACTIVE_TAB, payload });
  };
};

export const updateUsersPage = (payload) => {
  return async (dispatch, getState) => {
    dispatch({ type: UPDATE_USERS_PAGE, payload });
  };
};

export const updateUsersSearch = (payload) => {
  return async (dispatch, getState) => {
    dispatch({ type: UPDATE_USERS_SEARCH, payload });
  };
};
